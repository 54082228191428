import React, { useState, useEffect, useRef } from 'react'
import { Container, Row } from 'react-bootstrap'
import * as HeroIcons from 'react-icons/hi'
import { Link } from 'gatsby'

const TermsCard = ({ title, route }) => {
    const routing = `/term-condition-detail/${route}`
    return (
        <>
            <section style={{ marginRight: 15, marginLeft: 15, cursor: 'pointer' }}>
                <Container>
                    <Link to={`/term-condition-detail/${route}`}>
                        <Row className='terms-card'>
                            <h6 style={{ color: 'black' }}>{title}</h6>
                            <HeroIcons.HiChevronRight size={30} color='black' />
                        </Row>
                    </Link>
                </Container>
            </section>
            <div style={{ height: 20 }} />
        </>
    )
}

export default TermsCard