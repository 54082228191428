import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Footer from "../components/footer"
import Seo from "../components/seo"
import HeaderPages from "../components/header-pages"
import TermsCard from "../components/cards/terms-card"
import axios from 'axios'
import { API } from "../config/api"
import HelpCard from "../components/cards/help-card"
import { Container } from "react-bootstrap"
import * as HeroIcons from 'react-icons/hi'
import { navigate } from "gatsby"
import { Pagination } from "antd"

const HelpInformation = () => {
    const [Data, setData] = useState()
    const [ActivePage, setActivePage] = useState(1)
    const [Search, setSearch] = useState('')
    const fetchData = async (page = ActivePage, search = Search) => {
        const response = await axios.get(
            `${API.URL}/api/v1/help-information/head?page=${page}&size=20&search=${search}`
        ).then(response => {
            if (response?.data?.status === true) {
                setData(response?.data?.data)
                console.log("SUCCESS Get Help Information: ", response?.data);
            } else {
                setData(undefined)
                console.warn("WARNING Get Help Information: ", response?.data);
            }
        }).catch(err => {
            console.error("ERROR Get Help Information: ", err);
        })

        return response
    }

    const handlePageChange = (page) => {
        if (ActivePage != page) {
            setActivePage(page);
            // Panggil fungsi untuk memuat data dengan halaman yang diubah
            fetchData(page, '');
        }
    };

    const handleChangeSearch = (e) => {
        setSearch(e.target.value)
        fetchData(1, e.target.value)
    }

    console.log("Seaching: ", Search);

    useEffect(() => {
        fetchData()
    }, [])
    return (
        <Layout pageTitle="Informasi Bantuan | Klabbelanja">
            <Seo title="Informasi Bantuan | Klabbelanja" />
            <HeaderPages />
            <div style={{ height: 130 }} />
            <Container>
                <h4 style={{ color: 'black' }}>Daftar Informasi Bantuan</h4>
                <div style={{ height: 30 }} />
                <input type="text" placeholder="Cari informasi bantuan disini" style={{ width: '100%', borderRadius: 13, padding: 10 }} value={Search} onChange={(e) => handleChangeSearch(e)} />
                <div style={{ height: 30 }} />
                {Data ? (
                    <>
                        {Data?.data?.map((item) => (
                            <HelpCard title={item?.question} body={item?.answer} slug={item?.slug} />
                        ))}
                    </>
                ) : (
                    <p style={{ display: 'flex', justifyContent: 'center', color: 'black' }}>Tidak ada data</p>
                )}

                <div style={{ height: 50 }} />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Pagination defaultCurrent={1} onChange={(page) => handlePageChange(page)} current={ActivePage} total={Data?.count_data} />
                </div>
            </Container>
            {/* <DataTermCondition /> */}
            {/* <Footer /> */}
        </Layout>
    )
}

export default HelpInformation
