import React, { Fragment, useRef } from "react"
import { Link } from "gatsby"
import { Link as ScrollLink } from "react-scroll"

const NavLinksPages = () => {
  const subMenuEl = useRef(null)

  return (
    <ul className="one-page-scroll-menu navigation-box" ref={subMenuEl}>
      <li>
        <ScrollLink
          activeClass="current"
          to="banner"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          <Link to="/">
            Home
          </Link>
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to="blog"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          <Link to="/">
            Article
          </Link>
        </ScrollLink>
      </li>
    </ul>
  )
}

export default NavLinksPages
