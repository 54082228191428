import React, { useState, useEffect, useRef } from 'react'
import { Container, Row } from 'react-bootstrap'
import * as HeroIcons from 'react-icons/hi'
import { Link, navigate } from 'gatsby'
import Layout from '../layout'

const HelpCard = ({ title, body, slug }) => {
    return (
        <>
            <section style={{ marginRight: 15, marginLeft: 15, cursor: 'pointer' }}>
                <Container>
                    <div onClick={() => navigate(`/help-information/detail/${slug}`, {
                        state: {
                            title: title,
                            body: body
                        }
                    })}>
                        <Row className='terms-card'>
                            <div style={{ color: 'black' }} dangerouslySetInnerHTML={{ __html: title }} />
                            <HeroIcons.HiChevronRight size={30} color='black' />
                        </Row>
                    </div>
                </Container>
            </section>
            <div style={{ height: 20 }} />
        </>
    )
}

export default HelpCard